'use client';

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { getCartData } from '@api/cart';
import { getCatalogData } from '@api/catalog';
import { PRODUCTION_ENV } from '@lib/constants/environment';
import { GAMStorageService, GAMTrackService } from '@services';
import { ICartDataContext, ICartItem, IEditPostcardMessage, IHandleQty } from '@types';

const CartDataContext = createContext<ICartDataContext | undefined>(undefined);

const CartDataProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useState<ICartItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateCartData = () => {
    if (cart && cart.length) {
      const productIds = cart.map((i) => i.productId);
      getCatalogData({ query: `productId=${productIds}&withFilters=0` })
        .then((data) => {
          if (data?.docs && Array.isArray(data.docs) && data.docs.length) {
            const updatedCart = cart.map((i) => {
              const product = data.docs.find((p) => p.id === i.productId);
              return product ? { ...i, id: uuid(), product: { ...i.product, ...product } } : i;
            });
            setCart(updatedCart);
          }
        })
        .catch((error) => {
          console.error('Failed to fetch products in cart:', error);
        });
    } else {
      getCartData()
        .then((data) => {
          if (Array.isArray(data) && data.length) {
            setCart(data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch cart data:', error);
          setIsLoading(false);
        });
    }
  };

  // * set initial cart data from localStorage
  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const localCart = localStorage.getItem('cart');
      const initialCart: ICartItem[] = localCart ? JSON.parse(localCart) : [];

      if (Array.isArray(initialCart) && initialCart.length) {
        setCart(initialCart);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const editPostcardMessage: IEditPostcardMessage = (productId, postcardMessage) => {
    setCart((c) =>
      c.map((item) =>
        item.productId === productId
          ? {
              ...item,
              productOptions: {
                ...item.productOptions,
                postcardText: postcardMessage
              }
            }
          : item
      )
    );
  };

  const handleQty: IHandleQty = (action, item, qty) => () => {
    switch (action) {
      case 'increase':
        if (!qty) return;

        setCart((c) => {
          GAMTrackService.trackAddToCart({ ...item, qty: 1 });
          if (
            qty > item.product.inStockTotal &&
            item.product.hasPreorder &&
            item.product.inStockStatus !== 'preorder' &&
            item.product.inStockStatus !== 'soldout' &&
            !PRODUCTION_ENV
          ) {
            const preOrderCartItem: ICartItem = {
              id: uuid(),
              productId: item.productId,
              product: { ...item.product, inStockStatus: 'preorder' },
              qty: 1
            };
            return [...c, preOrderCartItem];
          } else if (!c.find((i) => i.productId === item.productId) && item.product.inStockStatus !== 'preorder') {
            return [...c, { id: uuid(), productId: item.productId, product: item.product, qty }];
          }

          return c.map((i) => (i.id === item.id ? { ...i, qty } : i));
        });
        return;

      case 'decrease':
        GAMTrackService.trackRemoveFromCart({ ...item, qty: 1 });
        if (qty === 0) {
          setCart((c) => c.filter((c) => c.id !== item.id));
        } else {
          setCart((c) => c.map((i) => (i.id === item.id ? { ...i, qty: qty || 1 } : i)));
        }
        return;

      case 'remove':
        GAMTrackService.trackRemoveFromCart(item);
        GAMStorageService.removeItemFromLongTerm(item.productId);
        setCart((c) => c.filter((c) => c.id !== item.id));
        return;

      default:
        return;
    }
  };

  const clearCart = () => {
    setCart([]);
    GAMStorageService.clearLongTermStore();
    localStorage.setItem('cart', JSON.stringify([]));
  };

  return (
    <CartDataContext.Provider
      value={{ cart, setCart, isLoading, handleQty, clearCart, editPostcardMessage, updateCartData }}
    >
      {children}
    </CartDataContext.Provider>
  );
};

const useCartData = () => {
  const context = useContext(CartDataContext);
  if (!context) {
    throw new Error('useCartData must be used within a CartDataProvider');
  }
  return context;
};

export { CartDataProvider, useCartData };
