import { API_URL } from '@lib/constants/environment';
import { ILocation, IPlaceType } from '@types';

// TODO replace with the proper types
export async function getLocations(queryString: string) {
  const res = await fetch(`${API_URL}/location/find`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ q: queryString }),
    cache: 'no-cache'
  });
  return res.json();
}

export async function getLocation(location: ILocation | IPlaceType | null): Promise<ILocation> {
  const res = await fetch(`${API_URL}/location/find-by-geo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(location),
    cache: 'no-cache'
  });
  return res.json();
}
