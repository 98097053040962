'use client';

import { createContext, useContext, useState } from 'react';

import { IMainMenuContext, IMainMenuData, IMainMenuProvider } from '@types';

const MainMenuContext = createContext<IMainMenuContext | undefined>(undefined);

const MainMenuProvider = ({ children, data }: IMainMenuProvider) => {
  const [mainMenu, setMainMenu] = useState<IMainMenuData[]>(data);

  return <MainMenuContext.Provider value={{ mainMenu, setMainMenu }}>{children}</MainMenuContext.Provider>;
};

const useMainMenu = () => {
  const context = useContext(MainMenuContext);
  if (!context) {
    throw new Error('useMainMenu must be used within a MainMenuProvider');
  }
  return context;
};

export { MainMenuProvider, useMainMenu };
