export const DEFAULT_DELIVERY_CITY = {
  id: '60891587e9d54f213c1987c6',
  state: 'Київська область',
  region: '',
  city: 'Київ',
  latitude: '50.450418000000000',
  longitude: '30.523541000000000',
  isCoveredByDeliveryNetwork: true,
  deliveryType: ['6082a3da56f552cc1921271c', '6082a3da56f552cc1921271f', '6082a3da56f552cc19212722'],
  shippingPricePerCity: 0,
  shippingPriceSmallOrder: 0,
  shippingPriceExactTime: 0,
  minOrderAmountForCourier: 300,
  delivery: {
    monday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    tuesday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    wednesday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    thursday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    friday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    saturday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    sunday: {
      hasDelivery: true,
      deliveryTimes: [
        '6082af0cac5bd65779690707',
        '6082af0cac5bd65779690708',
        '6082af0cac5bd65779690709',
        '6082af0cac5bd6577969070a',
        '6082af0cac5bd6577969070b',
        '6082af0cac5bd6577969070c',
        '6082af0cac5bd6577969070d'
      ],
      deliveryTimeSlots: []
    },
    _id: '655e2095bfe904087ec99e7b'
  }
};

export const DEFAULT_DELIVERY_LOCATION = {
  description: 'Київ (Київська область)',
  matched_substrings: [
    {
      length: 4,
      offset: 0
    }
  ],
  place_id: 'ChIJBUVa4U7P1EAR_kYBF9IxSXY',
  reference: 'ChIJBUVa4U7P1EAR_kYBF9IxSXY',
  structured_formatting: {
    main_text: 'Київ',
    main_text_matched_substrings: [
      {
        length: 4,
        offset: 0
      }
    ],
    secondary_text: 'Україна'
  },
  terms: [
    {
      offset: 0,
      value: 'Київ'
    },
    {
      offset: 6,
      value: 'Україна'
    }
  ],
  types: ['locality', 'political', 'geocode']
};
