import { API_URL } from '@lib/constants/environment';
import { ICatalogRequestPayload, ICatalogResponse } from '@types';
import { handleError } from '@utils/error-handling';

export async function getCatalogData({ query }: ICatalogRequestPayload): Promise<ICatalogResponse> {
  const res = await fetch(`${API_URL}/catalog${query ? '?' + query : ''}`, {
    cache: 'no-store'
  });
  return handleError<ICatalogResponse>(res);
}

export async function getCategoryCatalogData({ query, categoryId }: ICatalogRequestPayload): Promise<ICatalogResponse> {
  const res = await fetch(`${API_URL}/catalog/category/${categoryId}${query ? '?' + query : ''}`, {
    cache: 'no-store'
  });
  return handleError<ICatalogResponse>(res);
}

export async function getSpecialOffersCatalogData({ query }: ICatalogRequestPayload): Promise<ICatalogResponse> {
  const res = await fetch(`${API_URL}/catalog?specialOffers=1&${query || ''}`, {
    cache: 'no-store'
  });
  return handleError<ICatalogResponse>(res);
}

export async function getFavoritesCatalogData({ token, query }: ICatalogRequestPayload): Promise<ICatalogResponse> {
  const res = await fetch(`${API_URL}/catalog/watched-products${query ? '?' + query : ''}`, {
    cache: 'no-store',
    headers: { Authorization: token || '' }
  });
  return handleError<ICatalogResponse>(res);
}

export async function getRecentlyViewedCatalogData({
  token,
  query
}: ICatalogRequestPayload): Promise<ICatalogResponse> {
  const res = await fetch(`${API_URL}/catalog/viewed-products${query ? '?' + query : ''}`, {
    cache: 'no-store',
    headers: { Authorization: token || '' }
  });
  return handleError<ICatalogResponse>(res);
}
