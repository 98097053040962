import { DEFAULT_DELIVERY_LOCATION } from '@components/modals/places-autocomplete/default-delivery-city';
import { ILocation, IPlaceType } from '@types';

export function getAddressFromLocalStorage(setDefault: boolean = false): ILocation | IPlaceType | undefined {
  let lastUsedDeliveryAddress: ILocation | IPlaceType | undefined = undefined;
  try {
    if (typeof window !== 'undefined' && window.localStorage) {
      const addressFromStorage = localStorage.getItem('lastUsedDeliveryAddress');
      if (
        addressFromStorage &&
        addressFromStorage !== 'null' &&
        addressFromStorage !== 'undefined' &&
        addressFromStorage !== '{}' &&
        addressFromStorage !== "''" &&
        addressFromStorage !== '""'
      ) {
        lastUsedDeliveryAddress = JSON.parse(addressFromStorage);
      } else if (setDefault) {
        // * set "Київ" location as default
        lastUsedDeliveryAddress = DEFAULT_DELIVERY_LOCATION;
      }
    }
  } catch (error) {
    console.error(error);
  }
  return lastUsedDeliveryAddress;
}
