'use client';

import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { getLocation } from '@api/locations';
import { DEFAULT_DELIVERY_LOCATION } from '@components/modals/places-autocomplete/default-delivery-city';
import { ILocation, IPlaceType } from '@types';
import { getAddressFromLocalStorage } from '@utils/restoreDeliveryAddress';

interface IDeliveryAddressContext {
  pending: boolean;
  lastUsedDeliveryAddress?: ILocation;
  setPlaceToLocalAddress: (address?: IPlaceType) => void;
  setLastUsedDeliveryAddress: Dispatch<SetStateAction<ILocation | undefined>>;
}

const DeliveryAddressContext = createContext<IDeliveryAddressContext | undefined>(undefined);

const DeliveryAddressProvider = ({
  children,
  initialLocation
}: {
  children: ReactNode;
  initialLocation?: ILocation;
}) => {
  const [lastUsedDeliveryAddress, setLastUsedDeliveryAddress] = useState<ILocation | undefined>(initialLocation);
  const [pending, setPending] = useState(false);
  let deliveryAddressFromStorage = getAddressFromLocalStorage();

  const setPlaceToLocalAddress = (place?: IPlaceType) => {
    if (place) {
      localStorage.setItem('lastUsedDeliveryAddress', JSON.stringify(place));
      Cookies.set('last-used-delivery-address', JSON.stringify(place));
    }
  };

  useEffect(() => {
    if (deliveryAddressFromStorage && 'description' in deliveryAddressFromStorage) {
      if (deliveryAddressFromStorage.description === 'Київ, Україна') {
        deliveryAddressFromStorage.description = 'Київ (Київська область)';
      }
    } else {
      deliveryAddressFromStorage = undefined;
    }

    // * if we have address then skip the request
    if (lastUsedDeliveryAddress) return;

    setPlaceToLocalAddress(deliveryAddressFromStorage || DEFAULT_DELIVERY_LOCATION);

    // TODO this part of the code is fallback for the case when we don't have address in cookies
    setPending(true);
    getLocation(deliveryAddressFromStorage || DEFAULT_DELIVERY_LOCATION)
      .then((res) => {
        setLastUsedDeliveryAddress(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setPending(false));
  }, []);

  return (
    <DeliveryAddressContext.Provider
      value={{ pending, lastUsedDeliveryAddress, setPlaceToLocalAddress, setLastUsedDeliveryAddress }}
    >
      {children}
    </DeliveryAddressContext.Provider>
  );
};

const useDeliveryAddress = () => {
  const context = useContext(DeliveryAddressContext);
  if (!context) {
    throw new Error('useOrdersData must be used within a DeliveryAddressProvider');
  }
  return context;
};

export { DeliveryAddressProvider, useDeliveryAddress };
